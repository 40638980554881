import globalStyles from "GlobalStyles";
import {
    LxBaseControlContent,
    CCLocation,
    ControlContext,
    WheelPicker,
    LxReactSeparator,
    LxReactFlexibleCell,
    AmbientContext,
} from "LxComponents";
import UpperTempControl from "./upperTempControl";
import { useMemo, useContext, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import PropTypes from "prop-types";
import SleepModeWidget from "./sleepModeWidget";
import LxControlCommands from "../../../react-comps/LxReactControlContent/LxControlCommands";
import ExternalControlOverride from "./ExternalControlOverride";
import TargetTemp from "./TargetTemp";
import { sendValue, getQuickSelect } from "../utils";
import TimerWidget from "./TimerWidget";
import { createSettingsCell } from "./SettingsScreen";
import AcControlStateText from "./acControlStateText";
function CurrentTemp(props) {
    return (
        <Text style={styles.value}>
            {props.currentTemperature + `°${SandboxComponent.getTemperatureUnit()}`}
        </Text>
    )
}

CurrentTemp.propTypes = {
    targetTemperature: PropTypes.number,
}

function AcControlContent({navigation, route}) {
    const { control, states } = useContext(ControlContext);
    const { isSetByLogic: ogIsSetByLogic } = control || {};
    const isSetByLogic = ogIsSetByLogic.bind(control);
    const [showTempPicker, setShowTempPicker] = useState(false)
    const useFahrenheit = SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT)
    const { isAmbientMode } = useContext(AmbientContext);

    const displayTempPicker = () => {
        setShowTempPicker(true)
    }

    const showIrc = () => {
        control?.parentIRC && NavigationComp.showControlContent(control?.parentIRC);
    }

    const showIrcOrTempPicker = () => {
        let connectedIrc = control?.getParentIRC();
        let shouldShowIrc = !states.isExternalDisabled && Feature.AC_CONTROL_UPDATES_V2 && connectedIrc;
        if (shouldShowIrc) {
            showIrc();
        } else {
            displayTempPicker();
        }
    }

    const handleTempPickerSelect = (selected) => {
        const temperature = selected[0].value.label
        sendValue(control, Commands.AC.SET_TARGET, temperature)
        setShowTempPicker(false)
    }

    const generatePickerValues = () => {
        // TODO: what should the value ranges be for C and F
        let targetTempOptions;
        const upperTempBound = useFahrenheit ? 101 : 41

        targetTempOptions = Object.fromEntries(
            Array.from({ length: upperTempBound }, (_, i) => {
                if(i === 0) {
                    return null;
                }
                return [i, { label: i }]
            }).filter(x => x)
        );

        return Object.keys(targetTempOptions).map(key => {
            return targetTempOptions[key];
        })
    }

    const content = useMemo(() => {
        let content = []
        const isOperatedExternally =  Feature.AC_CONTROL_UPDATES_V2 && !states.isExternalDisabled;
        if (isSetByLogic("currentTemperature")) {
            content.push(createSettingsCell(_("controls.ac-control.current-temperature"), {
                    comp: CurrentTemp,
                    props: {
                        currentTemperature: states.currentTemperatureText,
                        useFahrenheit: useFahrenheit
                    }
                }
            ))
        }

        if (states.operatingModes?.length > 0) {
            content.push(
                createSettingsCell(
                    Feature.AC_CONTROL_UPDATES_V2
                        ? _('controls.ac-control.actual-ac-mode')
                        : _('controls.ac-control.mode'),
                    getQuickSelect(
                        states.operatingModes,
                        Commands.AC.SET_MODE,
                        'mode',
                        isSetByLogic('mode'),
                        control,
                        states,
                        null,
                        isOperatedExternally,
                    ),
                ),
            );
        }

        content.push(
            createSettingsCell(_('controls.ac-control.target-temperature'), {
                comp: TargetTemp,
                props: {
                    targetTemperature: states.targetTemperatureText,
                    displayTempPicker: displayTempPicker,
                    useFahrenheit: useFahrenheit,
                    isSetByLogic: isSetByLogic('targetTemperature'),
                    isOperatedExternally,
                },
            }),
        );

        if (states.fanSpeeds?.length > 0) {
            content.push(
                createSettingsCell(
                    _('controls.ac-control.fan-speed'),
                    getQuickSelect(
                        states.fanSpeeds,
                        Commands.AC.SET_FAN,
                        'fan',
                        isSetByLogic('fan'),
                        control,
                        states,
                        null,
                        isOperatedExternally,
                    ),
                ),
            );
        }

        if (states.airflows?.length > 0) {
            content.push(
                createSettingsCell(
                    _('controls.ac-control.air-flow-direction'),
                    getQuickSelect(
                        states.airflows,
                        Commands.AC.SET_AIR_DIR,
                        'ventMode',
                        isSetByLogic('ventMode'),
                        control,
                        states,
                    ),
                ),
            );
        }

        if(Feature.AC_CONTROL_UPDATES_V2) {
            content.push({
                title: _('settings'),
                cellElementStyle: {...globalStyles.customStyles.cellElement},
                onPress: () => {
                    navigation.navigate('SettingsScreen');
                },
                disclosureIcon: true,
                mainRightContent: {
                    props: {
                        isSetByLogic: false,
                    }
                }
            })
        }

        if (control?.parentIRC) {
            content.push({
                title: _("controls.ac-control.connected-irc"),
                cellElementStyle: {...globalStyles.customStyles.cellElement},
                disclosureIcon: true,
                onPress: () => {
                    showIrc();
                },
                mainRightContent: {
                    props: {
                        isSetByLogic: false,
                    }
                }
            })
        }

        return content

    }, [JSON.stringify(states)]);

    const wheelPickerData = [{
        dataset: generatePickerValues(),
        selectedIdx: Math.round(states.targetTemperature)
    }]

    const seperatorStyle = useMemo(() => {
        let style = {};
        if (isAmbientMode) {
            style.backgroundColor = globalStyles.colors.border;
        }
        return style;
    }, [isAmbientMode])

    return <LxBaseControlContent showStateIcon={false} showCommands={false} showStateText={false}>
        <AcControlStateText
            location={CCLocation.START} />

        <UpperTempControl
            displayTempPicker={showIrcOrTempPicker}
            location={CCLocation.START}
            sendValue={sendValue}
            isSetByLogic={isSetByLogic}
        />

        <ExternalControlOverride 
            isSetByLogic={isSetByLogic('externallyControlled')}
            hasExternalControl={control.details.externalControlled}
            selectedValue={states.isExternalDisabled}
            sendValue={sendValue}
        />
        <LxControlCommands />
        {Feature.AC_CONTROL_UPDATES_V2 ? <TimerWidget /> : <SleepModeWidget />}

        <View style={{ width: "100%", marginBottom: 30 }}>
            {content.map((item) => {
                return (
                    <View>
                        <LxReactSeparator style={seperatorStyle} />
                        <LxReactFlexibleCell
                            {...item}
                            {...(item.mainRightContent.props.isSetByLogic
                                ? {
                                      subTitle: _(
                                          'controls.ac-control.set-by-logic',
                                      ),
                                  }
                                : {})}
                            {...(item.mainRightContent.props
                                .isOperatedExternally
                                ? {
                                      subTitle: _(
                                          'controls.ac-control.set-by-auto-mode',
                                      ),
                                      subTitleStyle: {
                                        numberOfLines: 1,
                                      }
                                  }
                                : {})}
                            mainRightStyle={{...StyleSheet.flatten(item.mainRightStyle), minHeight: 44 }} // cell without subtitle was 2px smaller than with, so we set a min height to avoid jumping
                        />
                    </View>
                );
            })}
            <LxReactSeparator style={seperatorStyle} />
        </View>
        {showTempPicker ?
            <WheelPicker
                onSelect={handleTempPickerSelect}
                data={wheelPickerData} />
            : null
        }
    </LxBaseControlContent>
}

export default AcControlContent;

const styles = StyleSheet.create({
    value: {
        ...globalStyles.textStyles.body.default,
        color: globalStyles.colors.text.secondary,
    }
})
