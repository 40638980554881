import PropTypes from "prop-types";
import {useStatisticV2, useControl, Graph, DateViewType } from "LxComponents"
import {useMemo, useRef} from "react";
import generateLabels from "../Controls/energyFlowMonitorControl/content/util/generateLabels";
import useExtendedStats from "../Controls/energyFlowMonitorControl/content/hooks/useExtendedStats";


export default function LxReactControlStatsGraph(
    {
        controlUuid, viewType, fromUnixUtcTs, toUnixUtcTs, outputNames, dataPointUnit, style, preProcessFn, colorInfo,
        titleLeft, titleRight, ignoreNegative, format  = "%.2f kW", fixedUpperLimit, legend, forcedColors,
        fullStretch = false, onZoom, forcedGraphType
    }) {

    const actualStat = useStatisticV2(controlUuid, fromUnixUtcTs, toUnixUtcTs, outputNames, dataPointUnit, preProcessFn);
    const control = useControl(controlUuid);
    const forcedTimeRange = {
        start: fromUnixUtcTs,
        end: toUnixUtcTs
    };

    const extendedNodeStatsData = useExtendedStats({
        stats: actualStat,
        viewType: viewType,
        startTs: fromUnixUtcTs,
        endTs: toUnixUtcTs,
        newExtend: true
    });

    const shareRef = useRef(null)

    const getColor = (output, value, ts) => {
        return getColorForOutput(output,  value < 0, ts, value);
    }

    const getColorForOutput = (output, isNegative, ts, value) => {
        let result = null;
        if (!colorInfo) {
            // no color info provided
        } else if (typeof colorInfo === "string") {
            result = colorInfo;
        } else if (colorInfo.hasOwnProperty(output)) {
            if (typeof colorInfo[output] === "string") {
                result = colorInfo[output];
            } else {
                result = colorInfo[output][isNegative ? "negative" : "positive"];
            }
        }
        return result;
    }

    const {graphData, horizontalLabels, highlightDateFormat, bottomLabelsCentered} = useMemo(() => {
        if(!Array.isArray(actualStat.data)) {
            return {graphData:[], horizontalLabels:[]};
        }

        Debug.StatisticV2 && console.log(LxReactControlStatsGraph.name, "actualStats Data = ", actualStat.data);

        // whether or not the stats are averaged.
        const maxEntries = Debug.PURE_STATS ? 999999 : 248;
        const graphData = [];

        Debug.StatisticV2 && console.log(LxReactControlStatsGraph.name, "extended Data = ", extendedNodeStatsData);
        extendedNodeStatsData.forEach(stat => {
                const arr = [];
                if(viewType === DateViewType.Live) {

                    let storeValue = stat.values[0];
                    if (ignoreNegative && storeValue < 0) {
                        storeValue = 0;
                    }

                    arr.push({
                        color: getColor(actualStat.header[0].output, storeValue),
                        timestamp: stat.ts,
                        value: storeValue,
                    });

                } else {
                    stat.values.forEach((value, idx) => {
                        arr.push({
                            color: getColor(actualStat.header[idx].output, value),
                            timestamp: stat.ts,
                            value: value,
                        });
                    });
                }
                graphData.push(arr);
            }
        );

        Debug.StatisticV2 && console.log(LxReactControlStatsGraph.name, "graph Data = ", graphData)
        const {horizontalLabels, highlightDateFormat, bottomLabelsCentered} = generateLabels({viewType: viewType, data: graphData});
        return {
            graphData,
            horizontalLabels,
            highlightDateFormat,
            bottomLabelsCentered: bottomLabelsCentered,
        }
    }, [actualStat, viewType]);


    const handleExportPressed = () => {
        let csv = SandboxComponent.getCsvOfPackage(actualStat);
        let nameParts = [];
        let msName = ActiveMSComponent.getActiveMiniserver().msName,
            fileName,
            startDateTxt = moment(fromUnixUtcTs * 1000).format(DateType.DateReg),
            endDateTxt = moment(toUnixUtcTs * 1000).format(DateType.DateReg);
        nameParts.push(msName);
        nameParts.push(control.getName());
        nameParts.push(actualStat.header.map(header => {return header.title}).join("+"));
        if (startDateTxt !== endDateTxt) {
            nameParts.push(startDateTxt + "-" + endDateTxt);
        } else {
            nameParts.push(startDateTxt);
        }

        fileName = nameParts.join("_").replaceAll(/[ ,.]/g, "") + ".csv";

        exportTextFile(fileName, csv, "text/csv", shareRef.current);
    }

    const {label} = useMemo(() => {
        let splitTexts = lxUnitConverter.convertSplitAndApply(format || "%.2 kW", 1, true),
            unit = splitTexts.succTxt.replaceAll(" ", ""),
            label = unit;
        return {label};
    }, [viewType, format]);


    const pillFormat = useMemo(() => {
        return lxSplitFormat(format).valueFormat;
    }, [format])

    const graphType = useMemo(() => {
        if (forcedGraphType) {
            return forcedGraphType;
        } else if (viewType === DateViewType.Live) {
            return 'line';
        } else {
            return 'bar-side-by-side';
        }
    }, [viewType, forcedGraphType])

    return (
            <Graph
                style={style}
                fullStretch={fullStretch}
                type={graphType}
                horizontalLabels={horizontalLabels}
                titleLeft={fullStretch ? null : titleLeft}
                titleRight={fullStretch ? null : titleRight}
                datapoints={graphData}
                fixedUpperLimit={fixedUpperLimit}
                forcedColors={forcedColors}
                format={format}
                label={label}
                highlightDateFormat={highlightDateFormat}
                bottomLabelsCentered={bottomLabelsCentered}
                legend={legend}
                forcedTimeRange={forcedTimeRange}
                pillFormat={pillFormat}
                { ...(onZoom ? {onZoom: onZoom} : {})}
            />
    );
}

LxReactControlStatsGraph.propTypes = {
    titleLeft: PropTypes.string,
    titleRight: PropTypes.string,
    style: PropTypes.object,
    controlUuid: PropTypes.string.isRequired,
    fromUnixUtcTs: PropTypes.number.isRequired,
    toUnixUtcTs: PropTypes.number.isRequired,
    outputNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataPointUnit: PropTypes.oneOf([ "all", "hour", "day", "month", "year"]),
    preProcessFn: PropTypes.func,
    ignoreNegative: PropTypes.bool,
    format: PropTypes.string,
    forcedGraphType: PropTypes.oneOf(["line", "bar-side-by-side"]),
}
