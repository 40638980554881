import {createContext, useMemo} from "react";
import {useLiveState} from "LxComponents";
import PropTypes from "prop-types";
import useControlObject from "../customHooks/useControlObject";

export const ControlContext = createContext({
    reactableControl: null,
    control: null,
    states: null,
    contextMenuOptions: null,
})

function LxControlContextProvider (props) {
    const dynamicControl = useControlObject(props.controlUuid);
    const states = useLiveState(props.controlUuid).states;

    const contextValue = useMemo(() => {
        return {
            dynamicControl,
            control: dynamicControl.control,
            states,
            isAlert: props.isAlert,
            contextMenuOptions: []
        }
    }, [dynamicControl, states])

    return <ControlContext.Provider value={contextValue}>
        {props.children}
    </ControlContext.Provider>
}


LxControlContextProvider.propTypes = {
    controlUuid: PropTypes.string.isRequired,
    isAlert: PropTypes.bool
}

export default LxControlContextProvider;
