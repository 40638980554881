'use strict';

define(["IRoomControllerV2021ControlEnums"], function (CtrlEnums) {
    return class IrcStateOverview extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getTemplate() {
                return '' + '<div class="irc-state-overview">' + '<div class="irc-state-overview__main"></div>' + '</div>';
            } //endregion Static


        }; //endregion Static

        constructor(control, modeAction, tempAction) {
            super($(IrcStateOverview.Template.getTemplate()));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
            this.modeAction = modeAction;
            this.tempAction = tempAction;
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.elements = {
                main: this.element.find(".irc-state-overview__main"),
                thermostatCntr: this.element.find(".irc-state-overview__main")
            };
            this.buttons = {
                tempsButton: new GUI.LxButton(this, this.elements.main)
            };
            this.addToHandledSubviews(this.buttons.tempsButton);
            this.buttons.tempsButton.onButtonTapped = this.tempAction;
            return promise;
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates();
                if (this._reshowThermostat) {
                    this.receivedStates(this.control.getStates());
                    this._reshowThermostat = false;
                }

            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates(this.control.uuidAction);

            this._emptyThermostat();
            this._reshowThermostat = true;

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;

            this._updateThermostat(states);
        }

        _getThermostatIcon() {
            var icon = Icon.IRCV2021.THERMOSTAT_AUTO_UNIVERSAL;

            if (this.states.isManual || this.states.fixedSetpointInfo || this.control.singleComfortTemp) {
                icon = Icon.IRCV2021.THERMOSTAT_MANUAL_UNIVERSAL;
            } else if (!this.states.canHeat || !this.states.canCool || this.states.isHeatingNotAllowed || this.states.isCoolingNotAllowed) {
                icon = Icon.IRCV2021.THERMOSTAT_HEAT_OR_COOL_ONLY;
            }

            return icon;
        }

        _emptyThermostat() {
            if (this.elements.thermostat) {
                this.elements.thermostatCntr.empty();
                this.elements.thermostat = null;
            }
            this.thermostatIcon = null;
        }

        _updateThermostat(states) {
            var thermostatIcon = this._getThermostatIcon(),
                thermostat;

            if (this.thermostatIcon !== thermostatIcon) {
                this._emptyThermostat();
                this.thermostatIcon = thermostatIcon;

                this.elements.thermostatCntr.append(ImageBox.getResourceImageWithClasses(thermostatIcon, "main__thermostat"));
                this.elements.thermostat = this.elements.thermostatCntr.find(".main__thermostat");
                thermostat = this.elements.thermostat;
                this.svgParts = {
                    currZoneHeating: thermostat.find("#current-heating-zone"),
                    currZoneCooling: thermostat.find("#current-cooling-zone"),
                    currZoneFloating: thermostat.find("#current-floating-zone"),
                    currTempText: thermostat.find(".current-temperature-text"),
                    notHeatingOverlay: thermostat.find("#thermostat-no-heating"),
                    notCoolingOverlay: thermostat.find("#thermostat-no-cooling"),
                    notFloatingOverlay: thermostat.find("#thermostat-floating-zone"),
                    heatingContent: thermostat.find(this._heatingContentSelector(states)),
                    coolingContent: thermostat.find(this._coolingContentSelector(states)),
                    bgHeating: thermostat.find("#bg-heating"),
                    bgCooling: thermostat.find("#bg-cooling"),
                    heatingTargetTemp: thermostat.find("#value-temperature-heating-text"),
                    coolingTargetTemp: thermostat.find("#value-temperature-cooling-text"),
                    targetTemp: thermostat.find(".thermostat-value-temperature-target"),
                    targetTempHeating: thermostat.find("#target-temperature-heating"),
                    targetTempCooling: thermostat.find("#target-temperature-cooling"),
                    targetTempName: thermostat.find(".target-temperature-name"),
                    shadingIconHeat: thermostat.find("#icon-shading-heating"),
                    shadingIconCool: thermostat.find("#icon-shading-cooling"),
                    noHeatingIcon: thermostat.find("#icon-no-heating"),
                    noCoolingIcon: thermostat.find("#icon-no-cooling"),
                    coolingIcon: thermostat.find("#icon-cooling"),
                    heatingIcon: thermostat.find("#icon-heating")
                };
            }

            this.elements.thermostatCntr.toggleClass("irc-state-overview__main--heating", !!states.isHeating);
            this.elements.thermostatCntr.toggleClass("irc-state-overview__main--cooling", !!states.isCooling);
            this.elements.thermostatCntr.toggleClass("irc-state-overview__main--shading-active", !!states.shadingActive); // Update right part of the thermostat

            this.svgParts.currZoneCooling.toggle(!!states.inCoolingZone);
            this.svgParts.currZoneHeating.toggle(!!states.inHeatingZone);
            this.svgParts.currZoneFloating.toggle(!!states.inFloatingZone);
            this.svgParts.currTempText.text(_("controls.ircv2021.current-temp", {
                temp: this._fTmp(states.tempActual)
            })); // Update the thermostat itself

            this.svgParts.notHeatingOverlay.toggle(!states.isHeating);
            this.svgParts.notCoolingOverlay.toggle(!states.isCooling); // Update left part of thermostat

            this.svgParts.heatingContent.toggle(!!states.isHeating);
            this.svgParts.coolingContent.toggle(!!states.isCooling);
            this.svgParts.heatingTargetTemp.text(this._fTmp(states.currMinTemp));
            this.svgParts.coolingTargetTemp.text(this._fTmp(states.currMaxTemp));
            this.svgParts.targetTempName.text(states.activeModeText);
            this.svgParts.targetTemp.text(this._fTmp(states.tempTarget));
            this.svgParts.targetTempHeating.toggle(states.isHeating);
            this.svgParts.targetTempCooling.toggle(states.isCooling); // show/hide (not) heating/cooling state icons.

            var showNoHeatingIcon = (!states.canHeat && states.shouldHeat) || (states.canHeat && states.fixedSetpointInfo && !states.fixedSetpointInfo.heat);
            var showNoCoolingIcon = (!states.canCool && states.shouldCool) || (states.canCool && states.fixedSetpointInfo && !states.fixedSetpointInfo.cool);
            this.svgParts.noHeatingIcon.toggle(showNoHeatingIcon);
            this.svgParts.heatingIcon.toggle(!showNoHeatingIcon && !!states.isHeating);
            this.svgParts.noCoolingIcon.toggle(showNoCoolingIcon);
            this.svgParts.coolingIcon.toggle(!showNoCoolingIcon && !!states.isCooling); // If in cooling zone, but not cooling, show limit.

            if (states.inCoolingZone && !states.isCooling && states.canCool) {
                this.svgParts.coolingIcon.toggle(true);
                this.svgParts.coolingTargetTemp.toggle(true);
            }

            if (states.inHeatingZone && !states.isHeating && states.canHeat) {
                this.svgParts.heatingIcon.toggle(true);
                this.svgParts.heatingTargetTemp.toggle(true);
            } // in full auto mode, always show both the heating and cooling icons + temps


            if (!states.isManual && states.canHeat && states.canCool) {
                this.svgParts.heatingIcon.toggle(true);
                this.svgParts.heatingTargetTemp.toggle(true);
                this.svgParts.coolingIcon.toggle(true);
                this.svgParts.coolingTargetTemp.toggle(true);
            }

            if (states.hasShades && states.isCooling && states.shadingActive) {
                this.svgParts.shadingIconCool.toggle(true);
                this.svgParts.coolingTargetTemp.attr("x", 90);
            } else {
                this.svgParts.shadingIconCool.toggle(false);
                this.svgParts.coolingTargetTemp.attr("x", 115);
            }

            if (states.hasShades && states.isHeating && states.shadingActive) {
                this.svgParts.shadingIconHeat.toggle(true);
                this.svgParts.heatingTargetTemp.attr("x", 90);
            } else {
                this.svgParts.shadingIconHeat.toggle(false);
                this.svgParts.heatingTargetTemp.attr("x", 115);
            }
        }

        _heatingContentSelector() {
            return "" + "#bg-heating," + "#temperature-heating," + "#icon-shading-heating," + "#icon-heating," + "#icon-no-heating," + "#value-temperature-heating-text" + "";
        }

        _coolingContentSelector() {
            return "" + "#bg-cooling," + "#temperature-cooling," + "#icon-shading-cooling," + "#icon-cooling," + "#icon-no-cooling," + "#value-temperature-cooling-text" + "";
        }

        _fTmp(temp) {
            return this.control.formatTemp(temp);
        }

    };
});
