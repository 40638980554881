import {
    ControlContext,
    LxBaseControlContent,
    LxReactFlexibleCell,
    LxReactTextView
} from "LxComponents";
import {useContext, useState} from "react";
import globalStyles from "GlobalStyles";

export default function TextInputControlContent() {
    const {control, states} = useContext(ControlContext)
    const [internalText, setInternalText] = useState(checkText(states.text?.trim().length > 0 ? states.text : ""));

    const didModifyText = () => {
        return states.text !== internalText;
    }

    const onTextChanged = (value, isValid) => {
        setInternalText(value)
    }

    const sendValue = () => {
        if (didModifyText()) {
            let currentText = internalText;
            if (!Regex.TEXT_WITH_AT.test(currentText) && currentText !== "") {
                NavigationComp.showErrorPopup(false, _("adminIf.invalid-input"), _("controls.textinput.invalid_chars.text", {
                    chars: currentText.getInvalidChars(Regex.TEXT_WITH_AT)
                }));
            } else {
                control.sendCommand(Commands.format(Commands.TEXT_INPUT.SET_VALUE, currentText))
            }
        }
    }

    // TODO: Use proper button instead of flexibleCell
    return <LxBaseControlContent showStateIcon={false}>
        <LxReactTextView textChanged={onTextChanged}
                         autoFocus={true}
                         multiline={true}
                         enterAllowed={false}
                         placeholder={_("oFeM8D")}
                         alignment={"left"}
                         value={checkText(states.text)}
                         keepValueUpdated={true}
                         containerStyle={styles.textInputContainer}
                         textStyle={styles.textInput}/>
        <LxReactFlexibleCell section={0}
                             row={1}
                             containerStyle={globalStyles.customStyles.fullWidth}
                             cellElementStyle={globalStyles.customStyles.textCommandContainer}
                             title={_("controls.textinput.save-text")}
                             titleStyle={globalStyles.customStyles.textCommandText}
                             onPress={sendValue}
                             disabled={!didModifyText()}
        />
    </LxBaseControlContent>
}


const checkText = (text) => {
    if (!text) {
        return text
    }
    return text.replace(/&/g, "&amp;"); // no html encoding e.g &param -> ¶m
}

const styles = {
    textInputContainer: {
        height: 120,
        backgroundColor: globalStyles.colors.grey["300a18"],
        marginVertical: globalStyles.spacings.gaps.small,
        padding: globalStyles.spacings.gaps.small
    },
    textInput: {
        height: "100%"
    }
}
