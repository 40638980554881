'use strict';

define(["ChartView", "StatisticHeader"], function (ChartView, StatisticHeader) {
    return class StatisticScreen extends GUI.Screen {

        get reactHeaderShown() {
            return !this.hasMultipleOutputs
        }

        constructor(details) {
            super($('<div>'));
            this.control = ActiveMSComponent.getStructureManager().getControlByUUID(details.controlUUID); // acquire the statistics from the state container, as some of them may have been hidden depending on
            // a state (e.g. no battery in the energy monitor /  fronius)

            var allOutputs = SandboxComponent.getStatisticOutputsForUUID(this.control.uuidAction);
            this.supportedStatisticOutputs = [];

            for (var i = 0; i < allOutputs.length; i++) {
                if (allOutputs[i].visuType === Statistic.Type.NOT_SUPPORTED) continue;
                this.supportedStatisticOutputs.push(allOutputs[i]);
            }

            this.hasMultipleOutputs = allOutputs.length > 1;
            this.statisticOutput = this._findStatisticOutput(details.statisticOutputUUID);
            this.format = this._getFormatForStatisticOutput();
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.elements = {
                    chartViewContainer: $('<div class="statistic-screen__chart-container"></div>')
                };
                this.statisticHeader = new StatisticHeader(this.control.uuidAction, this.statisticOutput);
                return this.appendSubview(this.statisticHeader).then(() => {
                    this.element.append(this.elements.chartViewContainer);
                    if (this.hasMultipleOutputs) {
                        this._titleBar.setCircleDropDownListData(
                            arrayMap(this.supportedStatisticOutputs, [ 'name', 'uuid' ], [ 'title', 'id' ]),
                            true
                        );
                        this._titleBar.selectCircleDropDownEntry(this.statisticOutput.uuid);
                        this._titleBar.onDropDownListSelectionChanged = outputUuid => {
                            this._reloadChartView(outputUuid)
                        };
                    }
                    this._loadChartView();
                });
            });
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(() => {
                if (!this._initiallyLoaded) {
                    checkAndPrepareCanvasContextForRetina(this.chartView.getElement());
                }
            });
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(() => {
                this._backRegistration = this.statisticHeader.on("back", function () {
                    this.chartView.jumpBack(true);
                }.bind(this));
                this._forwardRegistration = this.statisticHeader.on("forward", function () {
                    this.chartView.jumpForward(true);
                }.bind(this)); // scroll events doesn't trigger activityTicks (performance), so suppress it completely (Wrike 126215044)

                this._activityTickInterval = setInterval(function () {
                    SandboxComponent.activityTick()
                }, 20 * 1000); // stop activityTick after 10min

                this._activityTickCanceler = setTimeout(function () {
                    clearInterval(this._activityTickInterval);
                    this._activityTickInterval = null;
                    this._activityTickCanceler = null;
                }.bind(this), 10 * 60 * 1000);

                if (!this._initiallyLoaded) {
                    this._initiallyLoaded = true;
                    this.chartView.GUIReady();
                }
            });
        }

        viewWillDisappear() {
            // do it here, because it is called asap!
            SandboxComponent.stopLoadingStatisticData();
            return super.viewWillDisappear();
        }

        viewDidDisappear() {
            this._titleBar.onDropDownListSelectionChanged = null;
            this.statisticHeader.off(this._backRegistration);
            this.statisticHeader.off(this._forwardRegistration);
            this._activityTickInterval && clearTimeout(this._activityTickInterval);
            this._activityTickCanceler && clearTimeout(this._activityTickCanceler);
            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            this.chartView.destroy();
            super.destroy();
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY_FULLSCREEN : AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return "Statistic/" + this.control.uuidAction + "/" + this.statisticOutput.uuid;
        }

        titleBarText() {
            return this.statisticOutput.name;
        }

        titleBarConfig() {
            let config = {
                leftSide: TitleBarCfg.Button.BACK
            };

            if (this.hasMultipleOutputs) {
                config.subTitleBar = 'dropDownMenu';
            }
            return config;
        }

        // Private methods
        _findStatisticOutput(uuid) {
            for (var i = 0; i < this.supportedStatisticOutputs.length; i++) {
                if (this.supportedStatisticOutputs[i].uuid === uuid) {
                    return this.supportedStatisticOutputs[i];
                }
            }
        }

        _getFormatForStatisticOutput() {
            if (this.statisticOutput && this.statisticOutput.visuType === Statistic.Type.DIGITAL) {
                /*if (this.control.details.text) {
                 format = {
                 0: this.control.details.text.off,
                 1: this.control.details.text.on
                 };
                 }*/
            } else {
                return (this.statisticOutput && this.statisticOutput.format) ? this.statisticOutput.format : "%.1f";
            }
        }

        /**
         * Reloads the chart view for the given outputUUID
         * @param outputUUID
         * @return Q.Promise<any>
         * @private
         */
        _reloadChartView(outputUUID) {
            SandboxComponent.stopLoadingStatisticData(); // set new output

            this.statisticOutput = this._findStatisticOutput(outputUUID);
            this.format = this._getFormatForStatisticOutput(); // reload header - maybe only update, not destroy!

            return this.removeSubview(this.statisticHeader).then(function () {
                this.statisticHeader = new StatisticHeader(this.control.uuidAction, this.statisticOutput);
                this.addToHandledSubviews(this.statisticHeader);
                this.statisticHeader.getElement().insertBefore(this.elements.chartViewContainer);
                this._backRegistration = this.statisticHeader.on("back", function () {
                    this.chartView.jumpBack(true);
                }.bind(this));
                this._forwardRegistration = this.statisticHeader.on("forward", function () {
                    this.chartView.jumpForward(true);
                }.bind(this)); // set back errorPopupShown

                this.errorPopupShown = false; // used to only show error popup once!
                // update titleBar
                this._titleBar.setTitleBarSideTexts(this.statisticOutput.name);

                if (this.hasMultipleOutputs) {
                    this._titleBar.selectCircleDropDownEntry(this.statisticOutput.uuid); // updates the name too
                } // delete chartView


                this.elements.chartViewContainer.empty();
                this.chartView.destroy(); // load chartView new

                this._loadChartView();

                checkAndPrepareCanvasContextForRetina(this.chartView.getElement());
                this.chartView.GUIReady();
            }.bind(this));
        }

        _loadChartView() {
            this.chartView = new ChartView(this, this, this.statisticOutput.visuType, this.control.statistic.frequency, this.format, this.control.getStatisticNoDataValue());
            this.elements.chartViewContainer.append(this.chartView.getElement());
        }

        // ChartView delegate
        setCurrentDataPoint(value, ts) {
            this.statisticHeader.updateStatisticHeader(value, ts);
        }

        // ChartView dataSource
        getMiniserverTime() {
            return SandboxComponent.getMiniserverTimeAsFakeUTC();
        }

        getDataForDate(date, range) {
            let weakThis = this;
            var dataPromise = SandboxComponent.getStatisticData(weakThis.control.uuidAction, weakThis.statisticOutput.uuid, date, range);
            dataPromise.then(function (packet) {
                Debug.Statistic.GUI && console.log("statisticCtrl dataRequest resolve for date:", date);
                if (!packet) return;

                if (packet.dataPoints.length) {
                    weakThis.chartView.receivedDataPackage(packet, true);
                } else {
                    console.warn("resolve with no data?", packet);
                }
            }, function (code) {
                Debug.Statistic.GUI && console.log("statisticCtrl dataRequest rejected for date:", date, "with code:", code);

                if (code === SupportCode.STATISTIC_NO_DATA_AVAILABLE) {
                    weakThis.chartView.receivedNoDataResponse(date);
                }

                if (!weakThis.errorPopupShown) {
                    weakThis.errorPopupShown = true;
                    NavigationComp.showSupportErrorPopup(code).then(function () {
                        this.ViewController.navigateBack();
                    }.bind(this), function () {// rejected, no message available
                    });
                }
            }, function (intermediatePacket) {
                Debug.Statistic.GUI && console.log("statisticCtrl dataRequest request notify for date:", date);
                if (!intermediatePacket) return;

                if (intermediatePacket.dataPoints.length) {
                    weakThis.chartView.receivedDataPackage(intermediatePacket, false);
                } else {
                    console.warn("nofity with no data?");
                }
            });
        }

        resetRequests() {
            SandboxComponent.stopLoadingStatisticData();
        }

        getMinimumDate() {
            var promise = SandboxComponent.getMinimumDateOfControl(this.control.uuidAction, this.statisticOutput.uuid);
            Q.when(promise).then(null, function () {
                if (this.isVisible(true)) {
                    var content = {
                        title: this.control.name,
                        message: _("statistics.no-data-available-message"),
                        buttonOk: true,
                        icon: Icon.INFO,
                        color: window.Styles.colors.green

};                    NavigationComp.showPopup(content).then(function () {
                        this.ViewController.navigateBack();
                    }.bind(this));
                }
            }.bind(this));
            return promise;
        }

    };
});
