'use strict';

define([], function () {
    return {
        Mode: {
            COMFORT: 1,
            ECO: 0,
            ECO_PLUS: 2,
            MANUAL: 3
        },
        Reason: {
            NONE: 0,
            PRESENCE: 1,
            WINDOW_OPEN: 2,
            COMFORT_OVERRIDE: 3,
            ECO_OVERRIDE: 4,
            ECO_PLUS_OVERRIDE: 5,
            PREPARE_STATE_HEAT_UP: 6,
            PREPARE_STATE_COOL_DOWN: 7,
            COOLING_FORBIDDEN: 9,   // Cooling not allowed. Schedule ignored.
            HEATING_FORBIDDEN: 10,  // Heating not allowed. Schedule ignored.
        },
        OperatingMode: {
            UNDEFINED: -1000,
            AUTOMATIC: {
                HEATING_AND_COOLING: 0,
                ONLY_HEATING: 1,
                ONLY_COOLING: 2
            },
            MANUAL: {
                HEATING_AND_COOLING: 3,
                ONLY_HEATING: 4,
                ONLY_COOLING: 5
            }
        },
        OperatingModeGroup: {
            HEATING_AND_COOLING: 0,
            ONLY_HEATING: 1,
            ONLY_COOLING: 2
        },
        ConnectedInputs: {
            COMFORT_TEMP: 1,
            ALLOWED_COMFORT_TOLERANCE: 2,
            LOWER_ABSENT_TEMP: 4,
            UPPER_ABSENT_TEMP: 8,
            FROST_PROTECT_TEMP: 16,
            HEAT_PROTECT_TEMP: 32
        },
        ScreenState: {
            OVERRIDE_SCREEN: ScreenState.iRoomControllerV2.OverrideScreen,
            SETTINGS: "IRoomControllerV2SettingsScreen",
            OPERATING_MODES: "IRoomControllerV2OperatingModesScreen",
            TIMES: "IRCV2DaytimerControlContentCalendar",
            TEMPERATURES: "IRoomControllerV2TemperaturesScreen",
            TEMPERATURE_SELECTOR: "IRoomV2ControlContentTemperatureSelection",
            AVG_TEMP_LIMITS: "IRCV2AvgTempLimitsScreen"
        },
        TempBoundaries: {
            ECO_MODE: {
                MIN_BOUNDARIES: {
                    get MIN() {
                        return pickCelsiusOrFahrenheit(5, 40);
                    },

                    get MAX() {
                        return pickCelsiusOrFahrenheit(35, 95);
                    }

                },
                MAX_BOUNDARIES: {
                    get MIN() {
                        return pickCelsiusOrFahrenheit(5, 40);
                    },

                    get MAX() {
                        return pickCelsiusOrFahrenheit(35, 95);
                    }

                }
            },
            ECO_PLUS_MODE: {
                ICE_PROTECTION: {
                    get MIN() {
                        return pickCelsiusOrFahrenheit(0, 32);
                    },

                    get MAX() {
                        return pickCelsiusOrFahrenheit(40, 104);
                    }

                },
                HEAT_PROTECTION: {
                    get MIN() {
                        return pickCelsiusOrFahrenheit(0, 32);
                    },

                    get MAX() {
                        return pickCelsiusOrFahrenheit(40, 104);
                    }

                }
            },
            COMFORT_TOLERANCE: {
                get MIN() {
                    return pickCelsiusOrFahrenheit(0.5, 0.5);
                },

                get MAX() {
                    return pickCelsiusOrFahrenheit(3, 3);
                }

            },
            Outside: {
                // for setting avg outside temperature limits
                get MIN() {
                    return pickCelsiusOrFahrenheit(-10, 14);
                },

                get MAX() {
                    return pickCelsiusOrFahrenheit(40, 104);
                }

            }
        },
        BoundaryInfo: {
            // for setting avg outside temperature limits
            NOT_ENOUGH_DATA: 0,
            // ouside temp available, but not enough time passed to have a trustworthy avg
            OK: 1,
            NO_DATA: 2 // no outside temp available, won't work with temp limits!

        },
        CurrentAutomatic: {
            REGULAR: 0,
            // Switch between heating and cooling based on the rooms needs
            OUTSIDE_TEMP_DEPENDANT: 1 // Keep in mind the outside temp limits when switching.

        }
    };
});
