'use strict';

define([], function () {
    return class TempLimitsView extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getContainer(thermostatIcon) {
                var content = '' + '<div class="temp-limits-view__content">' + '<div class="content__left">' + '<div class="left__cooling">' + getLeftCoolingItem() + '</div>' + '<div class="left__floating">' + '<div class="floating__spacing"></div>' + '<div class="floating__texts heating__cooling">' + '</div>' + '<div class="floating__spacing"></div>' + '</div>' + '<div class="left__heating">' + getLeftHeatingItem() + '</div>' + '</div>' + '<div class="content__center">' + ImageBox.getResourceImageWithClasses(thermostatIcon, "center__thermostat") + '</div>' + '<div class="content__right">' + getActiveZoneDiv("cooling") + getActiveZoneDiv("floating") + getActiveZoneDiv("heating") + '</div>' + '</div>';
                return content;
            };

            var getLeftCoolingItem = function getLeftCoolingItem() {
                return '' + '<div class="cooling__item left__item">' + getItemTexts(_("controls.climate.us.outside-temp.cooling-zone")) + getItemLimitButton() + '</div>';
            };

            var getLeftHeatingItem = function getLeftHeatingItem() {
                return '' + '<div class="heating__item left__item">' + getItemLimitButton() + getItemTexts(_("controls.climate.us.outside-temp.heating-zone")) + '</div>';
            };

            var getItemTexts = function getItemTexts(descr) {
                return '' + '<div class="item__texts">' + '<div class="texts__temperature"></div>' + '<div class="texts__description">' + descr + '</div>' + '</div>';
            };

            var getItemLimitButton = function getItemLimitButton() {
                return '' + '<div class="item__limit">' + '<div class="limit__line"></div>' + '<div class="limit__button">' + ImageBox.getResourceImageWithClasses(Icon.ClimateUs.EDIT, "button__icon") + '</div>' + '</div>';
            };

            var getActiveZoneDiv = function getActiveZoneDiv(type) {
                return '' + '<div class="right__zone right__' + type + '">' + '<div class="zone__title">' + _("controls.climate.us.outside-temp.active-zone") + '</div>' + '<div class="zone__spacing"></div>' + '<span class="zone__temperature"></span>' + '<div class="zone__spacing"></div>' + '</div>';
            };

            var getCurrentTemperatureSpan = function getCurrentTemperatureSpan(temp) {
                return '<span class="temperature__temp">' + temp + '</span>';
            };

            return {
                getTemplate: getTemplate,
                getCurrentTemperatureSpan: getCurrentTemperatureSpan
            };
        }(); //endregion Static

        constructor(control, absMin, absMax) {
            super($('<div/>'));
            Object.assign(this, StateHandler.Mixin);
            Object.assign(this, ContextMenuHandler.Mixin)
            this.control = control;
            this.absMinTemp = absMin;
            this.absMaxTemp = absMax;
        }

        viewDidLoad() {
            var promises = [super.viewDidLoad(...arguments)];
            this.element.append(TempLimitsView.Template.getTemplate(Icon.ClimateUs.THERMOMETER));
            this.elements = this.elements || {};
            this.elements.left = this.element.find(".content__left");
            this.elements.leftCooling = this.elements.left.find(".left__cooling");
            this.elements.leftCoolingTemp = this.elements.leftCooling.find(".texts__temperature");
            this.elements.leftCoolingButton = this.elements.leftCooling.find(".limit__button");
            this.elements.leftFloating = this.elements.left.find(".left__floating");
            this.elements.leftHeatingCooling = this.elements.leftFloating.find(".heating__cooling")
            this.elements.leftHeating = this.elements.left.find(".left__heating");
            this.elements.leftHeatingTemp = this.elements.leftHeating.find(".texts__temperature");
            this.elements.leftHeatingButton = this.elements.leftHeating.find(".limit__button");
            this.elements.center = this.element.find(".content__center");
            this.elements.right = this.element.find(".content__right");
            this.elements.rightCooling = this.elements.right.find(".right__cooling");
            this.elements.rightFloating = this.elements.right.find(".right__floating");
            this.elements.rightHeating = this.elements.right.find(".right__heating");
            this.buttons = {};
            this.buttons.coolingLimitButton = new GUI.LxButton(this, this.elements.leftCooling);
            this.buttons.heatingLimitButton = new GUI.LxButton(this, this.elements.leftHeating);
            this.addToHandledSubviews(this.buttons.coolingLimitButton);
            this.addToHandledSubviews(this.buttons.heatingLimitButton);
            this.buttons.coolingLimitButton.onButtonTapped = this._handleEditCoolingLimit.bind(this);
            this.buttons.heatingLimitButton.onButtonTapped = this._handleEditHeatingLimit.bind(this);

            this.appendHeatingCoolingText(this.elements.leftHeatingCooling);

            return Q.all(promises);
        }

        appendHeatingCoolingText(targetContainer) {
            let heatingCoolingText = "controls.climate.us.outside-temp.heat-cool-zone";
            let tempHeatingColor = Styles.colors.orange;
            let tempCoolingColor = Styles.colors.blue;
            this.appendReactComp({
                reactComp: GUI.LxReactLinkText,
                compProps: {
                    translationString: heatingCoolingText,
                    textStyle: {
                        fontFamily: Styles.fontSettings.families.regular,
                        fontSize: Styles.fontSettings.sizes.medium,
                        color: Styles.colors.white
                    },
                    identifiers: [
                        {
                            name: "heating",
                            style: {
                                color: tempHeatingColor
                            },
                            text: _("controls.climate.us.state.heating")
                        },
                        {
                            name: "cooling",
                            style: {
                                color: tempCoolingColor
                            },
                            text: _("controls.climate.us.state.cooling")
                        },
                    ]

                },
                target: targetContainer
            });
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._registerForStates();

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;

            this._setElementVisible(this.elements.rightCooling, states.outdoorTempCoolAllowed && !states.outdoorTempHeatAllowed);

            this._setElementVisible(this.elements.rightFloating, states.outdoorTempHeatCoolAllowed);

            this._setElementVisible(this.elements.rightHeating, states.outdoorTempHeatAllowed && !states.outdoorTempCoolAllowed);

            this._updateActiveZone(this.elements.rightCooling, states.actualOutdoorTemp);

            this._updateActiveZone(this.elements.rightFloating, states.actualOutdoorTemp);

            this._updateActiveZone(this.elements.rightHeating, states.actualOutdoorTemp);

            var coolingText = _("controls.climate.us.outside-temp.or-higher", {
                temp: lxFormat("%.1f°", states.maximumTempHeating)
            });

            var heatingText = _("controls.climate.us.outside-temp.or-lower", {
                temp: lxFormat("%.1f°", states.minimumTempCooling)
            });

            this.elements.leftCoolingTemp.text(coolingText);
            this.elements.leftHeatingTemp.text(heatingText);
        }

        _setElementVisible(jqElem, visible) {
            jqElem.css("visibility", visible ? "visible" : "hidden");
        }

        _updateActiveZone(jqElem, temp) {
            var tempElem = jqElem.find(".zone__temperature");
            var text = _("controls.climate.outside") + ", " + this.control.getOutsideTempModeName() + " ";

            tempElem.empty();
            tempElem.append([text, TempLimitsView.Template.getCurrentTemperatureSpan(lxFormat("%.1f°", temp))])
        }

        _handleEditHeatingLimit() {
            this._handleShowLimitEdit(_("controls.climate.us.temperature"), this.elements.leftHeatingButton[0], this.states.minimumTempCooling, // Only heating is allowed below this temp!
                this._handleHeatingLimitModified.bind(this), this.absMinTemp, this.states.maximumTempHeating);
        }

        _handleEditCoolingLimit() {
            this._handleShowLimitEdit(_("controls.climate.us.temperature"), this.elements.leftCoolingButton[0], this.states.maximumTempHeating, // Only cooling is allowed above this temp!
                this._handleCoolingLimitModified.bind(this), this.states.minimumTempCooling, this.absMaxTemp);
        }

        _handleShowLimitEdit(title, sourceElem, value, changedFn, min, max) {
            NavigationComp.showContextMenu(this, {
                value: value,
                format: "%.1f°",
                minValue: min,
                maxValue: max,
                onSliderDisappear: function (value) {
                    changedFn(value);
                }
            }, title, sourceElem, GUI.LxSliderContextMenu);
        }

        _handleHeatingLimitModified(value) {
            // beware! the heating limit on this screen is the minimumCoolingTempearture, as below this temp
            // only heating is allowed!
            this.control.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.SET_MIN_TEMP_COOLING, value));
        }

        _handleCoolingLimitModified(value) {
            // beware! the cooling limit on this screen is the maximumHeatingTempearture, as above this temp
            // only cooling is allowed!
            this.control.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.SET_MAX_TEMP_HEATING, value));
        }

    };
});
