import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import globalStyles from 'GlobalStyles';
import Icons from 'IconLib';
import { LxReactQuickSelect, LxReactFlexibleCell, LxReactText, ControlContext } from 'LxComponents';

const ExternalControlOverride = ({
    hasExternalControl = false,
    isSetByLogic = true,
    selectedValue,
    sendValue,
}) => {
    const { control } = useContext(ControlContext);
    if (!Feature.AC_CONTROL_UPDATES_V2 || !hasExternalControl) {
        return null;
    }

    const options = [
        { title: _('controls.ac-control.settings.manual-mode'), value: 1 },
        { title: _('controls.ac-control.settings.automatic-mode'), value: 0 },
    ];
    let title = selectedValue ? options[0].title : options[1].title;
    if (isSetByLogic) {
        title += ' ' + `(${_('controls.ac-control.set-by-logic')})`;
    }

    return isSetByLogic ? (
        <LxReactFlexibleCell
            title={title}
            titleStyle={StyleSheet.flatten(styles.quickSelectTitleStyleDisabled)}
            containerStyle={styles.containerStyle}
            cellElementStyle={styles.cellElementStyle}
            cellContainerStyle={styles.cellContainerStyle}
            mainContentStyle={styles.mainContentStyle}
            mainContentContainerStyle={styles.mainContentContainerStyle}
        />
    ) : (
        <LxReactFlexibleCell
            containerStyle={styles.containerStyle}
            cellElementStyle={styles.cellElementStyle}
            cellContainerStyle={styles.cellContainerStyle}
            mainContentStyle={styles.mainContentStyle}
            mainContentContainerStyle={styles.mainContentContainerStyle}
            mainContent={{
                comp: LxReactQuickSelect,
                props: {
                    options,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    iconComp: Icons.ChevronsUpDown,
                    textColor: globalStyles.colors.text.primary,
                    notSelectedTitle: '--',
                    title,
                    containerStyle: styles.quickSelectContainerStyle,
                    titleStyle: StyleSheet.flatten(
                        styles.quickSelectTitleStyle,
                    ),
                    tintColor: globalStyles.colors.text.secondary,
                    selectedValue: selectedValue
                        ? options[0].value
                        : options[1].value,
                    onOptionSelected: (value) => {
                        sendValue(control, Commands.AC.DISABLE_EXTERNAL, value);
                    },
                    noToggle: true,
                },
            }}
        />
    );
};

const styles = StyleSheet.create({
    containerStyle: {
        backgroundColor: globalStyles.colors.grey['300a36'],
        marginVertical: globalStyles.sizes.smallest,
        paddingHorizontal: globalStyles.sizes.smaller,
        paddingVertical: globalStyles.sizes.small + 1,
        borderRadius: globalStyles.sizes.smallest - 1,
        width: '100%',
        flexDirection: 'row',
        cursor: 'pointer',
    },

    cellElementStyle: {
        flex: 1,
        minHeight: 0,
    },

    cellContainerStyle: {
        paddingHorizontal: 0,
    },

    mainContentStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        width: '100%',
        cursor: 'pointer',
    },

    mainContentContainerStyle: {
        paddingVertical: 0,
        cursor: 'pointer',
    },

    quickSelectContainerStyle: {
        padding: 0,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },

    quickSelectTitleStyle: {
        padding: 0,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.primary,
    },

    quickSelectTitleStyleDisabled: {
        padding: 0,
        justifyContent: 'center',
        alignSelf: 'center',
        ...globalStyles.textStyles.body.bold,
        color: globalStyles.colors.text.tertiary,
        numberOfLines: 1,
    },
});

export default ExternalControlOverride;
