'use strict';

define("IRoomControllerV2Control", [ "Control", 'IRoomControllerV2ControlEnums', 'LxComponents', 'GlobalStyles' ], function (Control, IRoomControllerV2ControlEnums, {
    IrcTempDisplay
}, {
    default: globalStyles
}) {
    return class IRoomControllerV2Control extends Control {
        constructor() {
            super(...arguments);
        }

        /**
         * If true, controls of this type MAY use the rooms name instead of its own. E.g. this is important for light
         * controllers, heating controllers and other room based controls.
         */
        controlTypeUsesRoomName() {
            return true;
        }

        getButton0(states) {
            var theButton = null,
                presenceButton = {
                    iconSrc: Icon.IRCV2.BUTTON.PRESENCE,
                    reactIcon: false,
                    command: Commands.format(Commands.I_ROOM_V2_CONTROLLER.OVERRIDE.START, IRoomControllerV2ControlEnums.Mode.COMFORT)
                },
                comfortButton = {
                    iconSrc: Icon.PLUS,
                    reactIcon: false,
                    command: {
                        tap: this.setComfortTemperatureOffset.bind(this, states),
                        tick: this.setComfortTemperatureOffset.bind(this, states)
                    }
                },
                manualButton = {
                    iconSrc: Icon.PLUS,
                    reactIcon: false,
                    command: {
                        tap: this.setManualTemperatureOffset.bind(this, states),
                        tick: this.setManualTemperatureOffset.bind(this, states)
                    }
                };

            switch (states.activeMode) {
                case IRoomControllerV2ControlEnums.Mode.COMFORT:
                    if (states.hasCurrentOverrideEntry && states.overrideEntries[0].reason >= IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                        theButton = presenceButton;
                    } else {
                        theButton = comfortButton;
                    }

                    break;

                case IRoomControllerV2ControlEnums.Mode.MANUAL:
                    theButton = manualButton;
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                case IRoomControllerV2ControlEnums.Mode.ECO:
                    theButton = presenceButton;
                    break;
            }

            return theButton;
        }

        getButton1(states) {
            if (states.activeMode === IRoomControllerV2ControlEnums.Mode.COMFORT) {
                if (states.hasCurrentOverrideEntry && states.overrideEntries[0].reason >= IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {// Don't show a button when the IRCv2 is in prepare state
                } else {
                    return {
                        iconSrc: Icon.MINUS,
                        reactIcon: false,
                        command: {
                            tap: this.setComfortTemperatureOffset.bind(this, states, true),
                            tick: this.setComfortTemperatureOffset.bind(this, states, true)
                        }
                    };
                }
            } else if (states.activeMode === IRoomControllerV2ControlEnums.Mode.MANUAL) {
                return {
                    iconSrc: Icon.MINUS,
                    reactIcon: false,
                    command: {
                        tap: this.setManualTemperatureOffset.bind(this, states, true),
                        tick: this.setManualTemperatureOffset.bind(this, states, true)
                    }
                };
            }

            return null;
        }

        get reactIcon() {
            if (this.isGrouped()) {
                return null;
            }
            return IrcTempDisplay;
        }

        /**
         * Calculates and sends the correct value of the comfort temperature offset
         * @param states The controls states
         * @param decrease If true, the comfort temperature will be decreased
         */
        setComfortTemperatureOffset(states, decrease) {
            this.sendCommand(Commands.format(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.COMFORT_OFFSET, this.getTempStep(decrease) + states.comfortTemperatureOffset));
        }

        /**
         * Calculates and sends the correct value of the comfort temperature offset
         * @param states The controls states
         * @param decrease If true, the comfort temperature will be decreased
         */
        setManualTemperatureOffset(states, decrease) {
            this.sendCommand(Commands.format(Commands.I_ROOM_V2_CONTROLLER.SET_TEMP.MANUAL_TEMP, this.getTempStep(decrease) + states.tempTarget));
        }

        /**
         * Returns the timerModes object in the controls details
         * @return {*}
         */
        getTimerModes() {
            return cloneObject(this.details.timerModes);
        }

        /**
         * Returns the matching temperature string of a given modeId
         * It can either be a range (Eco and Eco +) or just one temperature (Comfort)
         * @param modeId
         * @param allowOffset
         * @return {*}
         */
        getTempStringOfMode(modeId, allowOffset) {
            var states = this.getStates(),
                tempString,
                format = this.details.format;

            switch (modeId) {
                case IRoomControllerV2ControlEnums.Mode.ECO:
                    tempString = lxFormat(format, states.comfortTemperature - states.absentMinOffset) + " … " + lxFormat(format, states.comfortTemperature + states.absentMaxOffset);
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                    tempString = lxFormat(format, states.frostProtectTemperature) + " … " + lxFormat(format, states.heatProtectTemperature);
                    break;

                default:
                    if (allowOffset) {
                        tempString = lxFormat(format, states.tempTarget);
                    } else {
                        tempString = lxFormat(format, states.comfortTemperature);
                    }

            }

            return tempString;
        }

        /**
         * Returns the name of a modeId or "Unknown" if the modeId is not known
         * @param modeId
         * @param allowOffset
         * @return {*}
         */
        getNameOfIRCTempMode(modeId, allowOffset) {
            var states = this.getStates(),
                timerModes = this.getTimerModes(),
                timerMode,
                name;

            if (modeId === IRoomControllerV2ControlEnums.Mode.MANUAL) {
                name = _('controls.irc.temp.manual');
            }

            timerMode = timerModes.find(function (mode) {
                return mode.id === modeId;
            });

            if (timerMode) {
                name = timerMode.name;
            } else if (!name) {
                name = _('unknown');
            }

            if (states.comfortTemperatureOffset !== 0 && allowOffset) {
                name += " (" + "<span style='color: " + this.getColorOfIRCTempMode(modeId) + "'>" + states.comfortTemperatureOffset.addSign(1) + "°</span>" + ")";
            }

            return name;
        }

        /**
         * Returns the name of either the given mode, or of the currently active one
         * @param [allowOffset]
         * @return {*}
         */
        getNameOfActiveMode(allowOffset) {
            var states = this.getStates(),
                modeId = states.activeMode,
                name,
                reason;

            if (states.hasCurrentOverrideEntry && states.overrideEntries[0].reason !== IRoomControllerV2ControlEnums.Reason.COMFORT_OVERRIDE && states.overrideEntries[0].isTimer) {
                reason = states.overrideEntries[0].reason;

                if (reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN || reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                    name = this._getPrepareStateName(reason);
                } else {
                    name = this._getActiveTimerDescription(modeId, states.overrideEntries[0]);
                }
            } else {
                name = this.getNameOfIRCTempMode(modeId, allowOffset);
            }

            return name;
        }

        /**
         * Returns the color for the given mode
         * @param ignoreTempOffset
         * @param modeId
         */
        getColorOfIRCTempMode(modeId, ignoreTempOffset) {
            var modeColor,
                states = this.getStates();

            if (modeId === undefined) {
                modeId = states.activeMode;
            }

            switch (modeId) {
                case IRoomControllerV2ControlEnums.Mode.ECO:
                case IRoomControllerV2ControlEnums.Mode.MANUAL:
                    modeColor = '#90a4ae';
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO_PLUS:
                    modeColor = Color.OAK;
                    break;

                default:
                    modeColor = this._getComfortModeColor(states, ignoreTempOffset);
            }

            return modeColor;
        }

        /**
         * Returns the iconSrc for the given mode
         * @param ignoreOverride
         * @param modeId
         */
        getIconOfIRCTempMode(modeId, ignoreOverride) {
            var modeIconSrc = Icon.IRCV2.REASON.CALENDAR,
                states = this.getStates();

            if (!ignoreOverride && states.hasCurrentOverrideEntry) {
                if (states.overrideEntries[0].isTimer) {
                    modeIconSrc = Icon.IRCV2.TIMER;
                } else {
                    switch (states.overrideEntries[0].reason) {
                        case IRoomControllerV2ControlEnums.Reason.PRESENCE:
                        case IRoomControllerV2ControlEnums.Reason.COMFORT_OVERRIDE:
                            modeIconSrc = Icon.IRCV2.REASON.PRESENCE;
                            break;

                        case IRoomControllerV2ControlEnums.Reason.WINDOW_OPEN:
                            modeIconSrc = Icon.IRCV2.REASON.WINDOW_OPEN;
                            break;

                        case IRoomControllerV2ControlEnums.Reason.ECO_OVERRIDE:
                            modeIconSrc = Icon.EnergyMonitor.PRODUCTION;
                            break;

                        case IRoomControllerV2ControlEnums.Reason.ECO_PLUS_OVERRIDE:
                            modeIconSrc = Icon.AlarmClock.SNOOZE;
                            break;

                        case IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP:
                            modeIconSrc = Icon.IRCV2.PREPARE_STATE.HEAT_UP;
                            break;

                        case IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN:
                            modeIconSrc = Icon.IRCV2.PREPARE_STATE.COOL_DOWN;
                            break;
                    }
                }
            }

            return modeIconSrc;
        }

        getTempModeDesc(modeId, allowPreparation) {
            var states = this.getStates(),
                timerMode,
                desc;

            if (typeof modeId !== "number") {
                modeId = states.activeMode;
            }

            timerMode = this.details.timerModes.find(function (timerMode) {
                return timerMode.id === modeId;
            });

            if (allowPreparation && states.hasCurrentOverrideEntry && states.overrideEntries[0].reason >= IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                    desc = _("controls.ircv2.heat-up-phase");
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN) {
                    desc = _("controls.ircv2.cool-down-phase");
                }
            } else if (timerMode) {
                desc = timerMode.description;
            }

            return desc || _('controls.irc.temp.manual');
        }

        /**
         * Helper method for converting the OperatingMode to our internally used OperatingModeGroup
         * @param mode
         * @return {*}
         */
        convertModeToGroup(mode) {
            var group;

            switch (mode) {
                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING:
                case IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_HEATING:
                    group = IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_HEATING;
                    break;

                case IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING:
                case IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_COOLING:
                    group = IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_COOLING;
                    break;

                default:
                    group = IRoomControllerV2ControlEnums.OperatingModeGroup.HEATING_AND_COOLING;
            }

            return group;
        }

        /**
         * Helper method for converting the internally used OperatingModeGroup to OperatingMode the Minserver understands
         * @param group
         * @param isAutomatic
         * @return {*}
         */
        convertGroupToMode(group, isAutomatic) {
            var mode;

            switch (group) {
                case IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_HEATING:
                    if (isAutomatic) {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_HEATING;
                    } else {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_HEATING;
                    }

                    break;

                case IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_COOLING:
                    if (isAutomatic) {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING;
                    } else {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.MANUAL.ONLY_COOLING;
                    }

                    break;

                default:
                    if (isAutomatic) {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING;
                    } else {
                        mode = IRoomControllerV2ControlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING;
                    }

            }

            return mode;
        }

        /**
         * Whether or not the IRCv2 is in automatic mode
         * @param opMode
         * @return {boolean}
         */
        isAutomaticMode(opMode) {
            var states = this.getStates();

            if (opMode === undefined) {
                opMode = states.operatingMode;
            }

            return opMode >= IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.HEATING_AND_COOLING && opMode <= IRoomControllerV2ControlEnums.OperatingMode.AUTOMATIC.ONLY_COOLING;
        }

        /**
         * Returns the name of the OperatingMode
         * eg. "Automatic only heating, no cooling allowed"
         * @param opMode
         * @return {*}
         */
        getNameOfOperatingMode(opMode) {
            var states = this.getStates(),
                opModeName,
                opModeSettingName;

            if (opMode === undefined) {
                opMode = states.operatingMode;
            }

            if (this.isAutomaticMode(opMode)) {
                opModeName = _('controls.ircv2.op-mode.automatic');
            } else {
                opModeName = _('controls.ircv2.op-mode.manual');
            }

            switch (this.convertModeToGroup(opMode)) {
                case IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_HEATING:
                    opModeSettingName = _('controls.ircv2.op-mode.settings.heating-only');
                    break;

                case IRoomControllerV2ControlEnums.OperatingModeGroup.HEATING_AND_COOLING:
                    opModeSettingName = _('controls.ircv2.op-mode.settings.heating-and-cooling');
                    break;

                case IRoomControllerV2ControlEnums.OperatingModeGroup.ONLY_COOLING:
                    opModeSettingName = _('controls.ircv2.op-mode.settings.cooling-only');
                    break;
            }

            return _('controls.ircv2.op-mode.active.description', {
                opMode: opModeName,
                opModeSetting: opModeSettingName
            });
        }

        getFriendlyValueName(event, operator) {
            switch (event.data.stateName) {
                case "tempActual":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + event.value.toString().replace(",", ".") + " °";

                case "activeMode":
                    return this.name + " " + event.data.stateTitle + " " + operator + " " + this.getNameOfIRCTempMode(event.value, false);

                default:
                    return "";
            }
        }

        specificCreateCmdText(cmdParts, cmdArguments) {
            var text = null,
                arg0,
                arg1,
                arg2;

            switch (cmdParts[1]) {
                case "override":
                    arg0 = parseInt(cmdParts[2]); // The operatingMode

                    switch (cmdParts.length) {
                        case 3:
                            // Start
                            text = this.getNameOfIRCTempMode(arg0, false);
                            break;

                        case 4:
                            // StartUntil
                            arg1 = parseInt(cmdParts[3]); // Seconds since 2009 defines, when the override will end

                            arg1 = new LxDate(arg1, true);
                            arg1 = arg1.diff(moment(), "seconds");
                            text = _('controls.ircv2.override.mode.description', {
                                mode: this.getNameOfIRCTempMode(arg0, false),
                                timeString: LxDate.formatSecondsShort(arg1, true)
                            });
                            break;

                        case 5:
                            // StartManual
                            arg1 = parseInt(cmdParts[3]); // Seconds since 2009 defines, when the override will end

                            arg1 = new LxDate(arg1, true);
                            arg1 = arg1.diff(moment(), "seconds");
                            arg2 = parseFloat(cmdParts[4]); // Temperature

                            text = _('controls.ircv2.override.temp.description', {
                                name: this.name,
                                timeString: LxDate.formatSecondsShort(arg1, true),
                                temp: arg2 + "°"
                            });
                            break;
                    }

                    break;

                case "stopOverride":
                    text = _('controls.irc.timer.stop');
                    break;

                case "setComfortModeTemp":
                    arg0 = parseFloat(cmdParts[2]);

                    if (arg0 > 0) {
                        text = _('controls.ircv2.comfort.increase-x', {
                            offset: arg0 + "°"
                        });
                    } else if (arg0 < 0) {
                        text = _('controls.ircv2.comfort.decrease-x', {
                            offset: arg0 + "°"
                        });
                    } else {
                        text = _('controls.ircv2.comfort.reset');
                    }

                    break;

                case "setOperatingMode":
                    arg0 = parseInt(cmdParts[2]); // Operating mode

                    text = this.getNameOfOperatingMode(arg0);
                    break;
            }

            return text;
        }

        /**
         * Returns the step for the comfort temperature offset
         * @param decrease If true, a negative value will be returned
         * @return {number}
         * @private
         */
        getTempStep(decrease) {
            var step = 0.5;

            if (SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT)) {
                step = 1;
            }

            step = decrease ? step * -1 : step;
            return step;
        }

        getFormatForTemp() {
            var format = "%.1f°";

            if (SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT)) {
                format = "%.0f°";
            }

            return format;
        }

        /**
         * Returns the color for the ComfortMode
         * Green = No offset is applied
         * Blue = A negative offset is applied
         * Orange = A positive offset is applied
         * @param states
         * @param ignoreTempOffset
         * @return {*}
         * @private
         */
        _getComfortModeColor(states, ignoreTempOffset) {
            var modeColor;

            if (states.comfortTemperatureOffset !== 0 && !ignoreTempOffset) {
                if (states.comfortTemperatureOffset > 0) {
                    modeColor = globalStyles.colors.orange;
                } else {
                    modeColor = globalStyles.colors.blue;
                }
            } else if (states.excessEnergyHeat && !ignoreTempOffset) {
                modeColor = globalStyles.colors.orange;
            } else if (states.excessEnergyCool && !ignoreTempOffset) {
                modeColor = globalStyles.colors.blue;
            } else {
                modeColor = globalStyles.colors.stateActive;
            }

            return modeColor;
        }

        _getActiveTimerDescription(modeId) {
            var name,
                timerModes = this.getTimerModes(),
                timerMode = timerModes.find(function (mode) {
                    return mode.id === modeId;
                });

            if (timerMode) {
                name = timerMode.description;
            } else {
                name = _('controls.ircv2.timer-active');
            }

            return name;
        }

        _getPrepareStateName(reason) {
            var name;

            switch (reason) {
                case IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP:
                    name = _('controls.ircv2.heat-up-phase');
                    break;

                case IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN:
                    name = _('controls.ircv2.cool-down-phase');
                    break;
            }

            return name;
        }

        _getCurrentDescription() {
            var states = this.getStates(),
                activeMode = states.activeMode,
                mode = this.getNameOfActiveMode(),
                isAutomatic = states.operatingMode < IRoomControllerV2ControlEnums.OperatingMode.MANUAL.HEATING_AND_COOLING;

            if (states.hasCurrentOverrideEntry) {
                if (states.overrideEntries[0].isTimer) {
                    return isAutomatic ? _("controls.ircv2.timer.active.ignore") : _("controls.ircv2.timer.active.manuel");
                } else if (states.overrideEntries[0].source) {
                    return this._getCurrentDescriptionWithSource(states, isAutomatic);
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.PRESENCE) {
                    return _("controls.ircv2.override.presence.desc", {
                        mode: mode
                    });
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_HEAT_UP) {
                    return this._getHeatUpDescription(activeMode);
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.PREPARE_STATE_COOL_DOWN) {
                    return this._getCoolDownDescription(activeMode);
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.COOLING_FORBIDDEN) {
                    return _("controls.climate.disallow.cooling.state") + " " + _("controls.daytimer.ignored");
                } else if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.HEATING_FORBIDDEN) {
                    return _("controls.climate.disallow.heating.state") + " " + _("controls.daytimer.ignored");
                }

            } else {
                return _("controls.ircv2.mode.ok.calendar", {
                    mode: mode
                });
            }
        }

        _getHeatUpDescription(activeMode) {
            var desc;

            switch (activeMode) {
                case IRoomControllerV2ControlEnums.Mode.COMFORT:
                    desc = _("controls.ircv2.heat-up-phase.desc.comfort");
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO:
                    desc = _("controls.ircv2.heat-up-phase.desc.economy");
                    break;

                default:
                    desc = _("controls.ircv2.heat-up-phase");
            }

            return desc;
        }

        _getCoolDownDescription(activeMode) {
            var desc;

            switch (activeMode) {
                case IRoomControllerV2ControlEnums.Mode.COMFORT:
                    desc = _("controls.ircv2.cool-down-phase.desc.comfort");
                    break;

                case IRoomControllerV2ControlEnums.Mode.ECO:
                    desc = _("controls.ircv2.cool-down-phase.desc.economy");
                    break;

                default:
                    desc = _("controls.ircv2.cool-down-phase");
            }

            return desc;
        }

        /**
         * Builds the description for the current active override entry, if the overrideEntry provides a source
         * A source can be the name of the input connected to IC, IA or IO
         * @param states
         * @param isAutomatic
         * @return {*}
         * @private
         */
        _getCurrentDescriptionWithSource(states, isAutomatic) {
            if (states.overrideEntries[0].reason === IRoomControllerV2ControlEnums.Reason.WINDOW_OPEN) {
                if (isAutomatic) {
                    return _("controls.ircv2.window.opened.ignore", {
                        input: states.overrideEntries[0].source
                    });
                }

                return _("controls.ircv2.window.opened.manuel", {
                    input: states.overrideEntries[0].source
                });
            } else {
                if (isAutomatic) {
                    return _("controls.ircv2.input.active.ignore", {
                        input: states.overrideEntries[0].source
                    });
                }

                return _("controls.ircv2.input.active.manuel", {
                    input: states.overrideEntries[0].source
                });
            }
        }

    };
});
